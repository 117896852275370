import React, { useState, useRef, useEffect, useCallback } from "react";
import { useDropzone } from "react-dropzone";

import logo from "./keittio3.png";
import logo2 from "./keittio4.png";
import bgInit from "./bg.jpg";
import bg1 from "./bg1.jpg";
import bg2 from "./bg2.jpg";
import bg3 from "./bg3.jpg";
import bg4 from "./bg4.jpg";
import bg5 from "./bg5.jpg";
import bg6 from "./bg6.jpg";
import bg7 from "./bg7.jpg";
import bg8 from "./bg8.jpg";

import "./App.css";
import { Container, Row, Col, Input, Label, Card, CardHeader, CardBody } from "reactstrap";

function App() {
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [prevX, setPrevX] = useState(0);
  const [prevY, setPrevY] = useState(0);
  const [move, setMove] = useState(false);
  const [bg, setBG] = useState(bg1);
  const [bgSize, setBGSize] = useState(500);
  const [origSize, setOrigSize] = useState(500);
  const [scale, setScale] = useState(100);

  const [cont, setCont] = useState(true);
  const imgEl = useRef<HTMLImageElement>(null);
  const [selected, setSelected] = useState(2);
  const [error, setError] = useState("");
  const [ownImage, setOwnImage] = useState<string | ArrayBuffer | null>("");
  const [factor, setFactor] = useState(0.4);
  const [kitchen, setKitchen] = useState(1);

  const onDrop = useCallback((files) => {
    if (files.length && files.length > 1) {
      setError("Vain yksi tiedosto");
    } else if (!files[0].type.includes("image")) {
      setError("Vain kuvatiedostot");
    } else {
      setError("");
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        const image = reader.result;
        setOwnImage(image);
      };
      reader.readAsDataURL(files[0]);
    }
    // Do something with the files
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const bgImages = [logo, logo2];

  const moveBG = (xMove: number, yMove: number) => {
    if (move) {
      setX(prevX + xMove - startX);
      setY(prevY + yMove - startY);
    }
  };

  useEffect(() => {
    setBGSize(Math.floor(Number((origSize * scale) / 100)));
  }, [scale, origSize]);

  const setImg = async (img: string, index: number) => {
    setBG(img);
    setSelected(index);
    setScale(100);

    // Jimp.read(bgInit).then(async (bg) => {
    //   bg.rotate(4);
    //   const val = await bg.getBase64Async(Jimp.MIME_JPEG);
    //   setBG(val);
    // });

    if (imgEl && imgEl.current) {
      const width = imgEl.current.offsetWidth - Math.floor(imgEl.current.offsetWidth * factor);
      setBGSize(width);
      setOrigSize(width);
    }
  };

  const options = {
    backgroundRepeat: cont ? "repeat" : "no-repeat",
    backgroundSize: bgSize + "px",
  };

  return (
    <div className="row">
      <div className="entry-content column small-12">
        <div className="perinteinen">
          <div className="content-area">
            <header className="App-header">
              <div
                onMouseDown={(e) => {
                  setStartX(e.pageX);
                  setStartY(e.pageY);
                  setMove(true);
                }}
                onMouseUp={() => {
                  setMove(false);
                  setPrevX(x);
                  setPrevY(y);
                }}
                onMouseLeave={() => {
                  if (move) {
                    setMove(false);
                    setPrevX(x);
                    setPrevY(y);
                  }
                }}
                onMouseMove={(e) => moveBG(e.pageX, e.pageY)}
                style={{
                  ...options,
                  backgroundImage: "url(" + bg + ")",
                  backgroundPosition: "absolute",
                  backgroundPositionX: x,
                  backgroundPositionY: y,
                }}
              >
                <img
                  style={{ margin: 0 }}
                  ref={imgEl}
                  src={bgImages[kitchen - 1]}
                  className="App-logo"
                  alt="logo"
                  id="kuva"
                />
              </div>
            </header>
          </div>
        </div>
        <div className="entry-content column samall-12">
          <div className="two-columns row">
            <div className="column small-12 medium-6 large-6 left">
              <Col className="controls" sm="12" md="6" style={{ paddingTop: 0, color: "#ffffff" }}>
                <Card style={{ margin: "20px", borderColor: "#000000" }}>
                  <CardHeader
                    style={{
                      backgroundColor: "#555555",
                      borderTopLeftRadius: 5,
                      borderTopRightRadius: 5,
                      paddingBottom: "1px",
                    }}
                  >
                    <h4
                      style={{
                        color: "#ffffff",
                        marginLeft: "15px",
                        paddingTop: 5,
                      }}
                    >
                      Valitse taustakuva
                    </h4>
                  </CardHeader>
                  <CardBody
                    style={{
                      backgroundColor: "#999999",
                      borderBottomLeftRadius: 5,
                      borderBottomRightRadius: 5,
                      paddingBottom: 10,
                    }}
                  >
                    <table style={{ border: "none", borderSpacing: "0px" }}>
                      <tbody style={{ border: 0, backgroundColor: "#999999" }}>
                        <tr style={{ border: 0, backgroundColor: "#999999" }}>
                          <td style={{ border: 0, backgroundColor: "#999999", textAlign: "center" }}>
                            <img
                              alt=""
                              src={bg1}
                              style={{
                                width: "160px",
                                margin: 20,
                                border: selected === 1 ? "2px solid #000000" : "2px solid #CCCCCC",
                                cursor: "pointer",
                              }}
                              onClick={() => setImg(bg1, 1)}
                            />
                          </td>
                          <td style={{ border: 0, backgroundColor: "#999999", textAlign: "center" }}>
                            <img
                              alt=""
                              src={bg2}
                              style={{
                                width: "160px",
                                margin: 20,
                                border: selected === 2 ? "2px solid #000000" : "2px solid #CCCCCC",
                                cursor: "pointer",
                              }}
                              onClick={() => setImg(bg2, 2)}
                            />
                          </td>
                        </tr>

                        <tr style={{ border: 0, backgroundColor: "#999999" }}>
                          <td style={{ border: 0, backgroundColor: "#999999", textAlign: "center" }}>
                            <img
                              alt=""
                              src={bg3}
                              style={{
                                width: "160px",
                                margin: 20,
                                border: selected === 1 ? "2px solid #000000" : "2px solid #CCCCCC",
                                cursor: "pointer",
                              }}
                              onClick={() => setImg(bg3, 1)}
                            />
                          </td>
                          <td style={{ border: 0, backgroundColor: "#999999", textAlign: "center" }}>
                            <img
                              alt=""
                              src={bg4}
                              style={{
                                width: "160px",
                                margin: 20,
                                border: selected === 2 ? "2px solid #000000" : "2px solid #CCCCCC",
                                cursor: "pointer",
                              }}
                              onClick={() => setImg(bg4, 2)}
                            />
                          </td>
                        </tr>

                        <tr style={{ border: 0, backgroundColor: "#999999" }}>
                          <td style={{ border: 0, backgroundColor: "#999999", textAlign: "center" }}>
                            <img
                              alt=""
                              src={bg5}
                              style={{
                                width: "160px",
                                margin: 20,
                                border: selected === 1 ? "2px solid #000000" : "2px solid #CCCCCC",
                                cursor: "pointer",
                              }}
                              onClick={() => setImg(bg5, 1)}
                            />
                          </td>
                          <td style={{ border: 0, backgroundColor: "#999999", textAlign: "center" }}>
                            <img
                              alt=""
                              src={bg6}
                              style={{
                                width: "160px",
                                margin: 20,
                                border: selected === 2 ? "2px solid #000000" : "2px solid #CCCCCC",
                                cursor: "pointer",
                              }}
                              onClick={() => setImg(bg6, 2)}
                            />
                          </td>
                        </tr>

                        <tr style={{ border: 0, backgroundColor: "#999999" }}>
                          <td style={{ border: 0, backgroundColor: "#999999", textAlign: "center" }}>
                            <img
                              alt=""
                              src={bg7}
                              style={{
                                width: "160px",
                                margin: 20,
                                border: selected === 1 ? "2px solid #000000" : "2px solid #CCCCCC",
                                cursor: "pointer",
                              }}
                              onClick={() => setImg(bg7, 1)}
                            />
                          </td>
                          <td style={{ border: 0, backgroundColor: "#999999", textAlign: "center" }}>
                            <img
                              alt=""
                              src={bg8}
                              style={{
                                width: "160px",
                                margin: 20,
                                border: selected === 2 ? "2px solid #000000" : "2px solid #CCCCCC",
                                cursor: "pointer",
                              }}
                              onClick={() => setImg(bg8, 2)}
                            />
                          </td>
                        </tr>

                        {ownImage && (
                          <tr style={{ border: 0, backgroundColor: "#999999" }}>
                            <td
                              colSpan={2}
                              style={{ border: 0, backgroundColor: "#999999", textAlign: "center" }}
                            >
                              <img
                                alt=""
                                src={String(ownImage)}
                                style={{
                                  width: "200px",
                                  margin: 20,
                                  border: selected === 3 ? "2px solid #000000" : "2px solid #CCCCCC",
                                  cursor: "pointer",
                                }}
                                onClick={() => setImg(String(ownImage), 3)}
                              />{" "}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <div
                      {...getRootProps()}
                      style={{
                        width: "90%",
                        marginLeft: "15px",
                        marginRight: "25px",
                        borderRadius: "5px",
                        border: "1px solid #cccccc",
                        backgroundColor: "#dddddd",
                        padding: "10px",
                        textAlign: "center",
                        color: "#333333",
                      }}
                    >
                      <input {...getInputProps()} />
                      {isDragActive ? (
                        <p>Tiputa tänne</p>
                      ) : (
                        <p>Raahaa oma kuva tai klikkaa valitaksesi kuva</p>
                      )}
                    </div>

                    <p style={{ color: "red", marginLeft: "15px", marginTop: "5px" }}>
                      <b>{error}</b>
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </div>
            <div className="column small-12 medium-6 large-6 right">
              <Col
                className="controls"
                sm="12"
                md="6"
                style={{ paddingTop: 0, color: "#ffffff", paddingBottom: "50px" }}
              >
                <Card style={{ margin: "20px", borderColor: "#000000" }}>
                  <CardHeader
                    style={{
                      backgroundColor: "#555555",
                      borderTopLeftRadius: 5,
                      borderTopRightRadius: 5,
                      paddingBottom: "1px",
                    }}
                  >
                    <h4
                      style={{
                        color: "#ffffff",
                        marginLeft: "15px",
                        paddingTop: 5,
                      }}
                    >
                      Valitse keittiö
                    </h4>
                  </CardHeader>
                  <CardBody
                    style={{
                      backgroundColor: "#999999",
                      borderBottomLeftRadius: 5,
                      borderBottomRightRadius: 5,
                    }}
                  >
                    <div className="two-columns row" style={{ marginTop: 0 }}>
                      <div className="column small-12 medium-6 large-6 left">
                        <img
                          onClick={() => {
                            setKitchen(1);
                            setFactor(0.4);
                          }}
                          src={logo}
                          style={{
                            cursor: "pointer",
                            maxWidth: "90%",
                            border: kitchen === 1 ? "2px solid #ffffff" : "",
                            margin: 10,
                          }}
                          alt="logo"
                        />
                      </div>
                      <div className="column small-12 medium-6 large-6 right">
                        <img
                          onClick={() => {
                            setKitchen(2);
                            setFactor(0.11);
                          }}
                          src={logo2}
                          style={{
                            cursor: "pointer",
                            maxWidth: "90%",
                            border: kitchen === 2 ? "2px solid #ffffff" : "",
                            margin: 10,
                            marginTop: 20,
                          }}
                          alt="logo2"
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card style={{ margin: "20px", borderColor: "#000000" }}>
                  <CardHeader
                    style={{
                      backgroundColor: "#555555",
                      borderTopLeftRadius: 5,
                      borderTopRightRadius: 5,
                      paddingBottom: "1px",
                    }}
                  >
                    <h4
                      style={{
                        color: "#ffffff",
                        marginLeft: "15px",
                        paddingTop: 5,
                      }}
                    >
                      Asetukset
                    </h4>
                  </CardHeader>
                  <CardBody
                    style={{
                      backgroundColor: "#999999",
                      borderBottomLeftRadius: 5,
                      borderBottomRightRadius: 5,
                    }}
                  >
                    <div style={{ paddingLeft: "15px", paddingBottom: 35 }}>
                      <Label>Taustakuvan leveys (% keittiön leveydestä)</Label>
                      <div style={{ width: "90%" }}>
                        <Input
                          type="range"
                          min={20}
                          max={200}
                          value={scale}
                          onChange={(e) => setScale(Number(e.target.value))}
                          style={{ boxShadow: "none", width: "90%" }}
                        />
                      </div>
                      <div style={{ width: "100%", textAlign: "center" }}>
                        <b>{scale}%</b>
                      </div>

                      <div style={{ marginLeft: 10 }}>
                        <div style={{ width: "10%", float: "left" }}>
                          <Input
                            style={{ boxShadow: "none", width: "10%" }}
                            type="checkbox"
                            checked={cont}
                            onChange={() => setCont(!cont)}
                          />{" "}
                        </div>
                        <div style={{ width: "80%", float: "right" }}>Täyty koko tausta</div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card style={{ margin: "20px", borderColor: "#000000" }}>
                  <CardHeader
                    style={{
                      backgroundColor: "#555555",
                      borderTopLeftRadius: 5,
                      borderTopRightRadius: 5,
                      paddingBottom: "1px",
                    }}
                  >
                    <h4
                      style={{
                        color: "#ffffff",
                        marginLeft: "15px",
                        paddingTop: 5,
                      }}
                    >
                      Ohjeet
                    </h4>
                  </CardHeader>
                  <CardBody
                    style={{
                      backgroundColor: "#999999",
                      borderBottomLeftRadius: 5,
                      borderBottomRightRadius: 5,
                    }}
                  >
                    <div style={{ paddingLeft: "15px" }}>
                      <p style={{ marginTop: 0, paddingTop: 5, paddingBottom: 5 }}>
                        Kuvalta vaadittava resoluutio on noin 1 pikseli / 1mm. Mikäli kuvasi resoluutio
                        on esimerkiksi 2000px leveyssuuntaan, siitä voidaan valmistaa noin 2m leveä
                        taustakuva. Tätä pienemmillä resoluutioilla kuvasta alkaa tulla rakeinen.
                      </p>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
